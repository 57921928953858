export const uploadDocumentURL = (url) => {
  return `${url}`;
};
export const fetchVehiclesURL = (page, search) => {
  return `/vehicle/all?page=${page}${search}`;
};

export const deleteVehicleRecordURL = () => {
  return `vehicle/delete-vehicle-unavailable-record`;
};

export const fetchDriverRecordsURL = (page, search = '') => {
  return `/vendor/all-drivers/other?page=${page}${search}`;
};

export const fetchZastDriverRecordURL = (page, search = '') => {
  return `/vendor/all-drivers/zast?page=${page}${search}`;
};

export const fetchAllZastDriverListURL = (ascending) => {
  return `/vendor/all-drivers/zast?all=true&ascending=${ascending}`;
};

export const fetchRouteTatsURL = (page, search) => {
  return `/booking/route-tat?page=${page}${search}`;
};

export const fetchCityDetailsURL = (page, search = '', type = '') => {
  return `/booking/all-cities?page=${page}${search}&type=${type}`;
};

export const fetchCityLatLongURL = (search = '') => {
  return `/booking/get-city-latlong?${search}`;
};

export const fetchRecordsUrl = (status) => {
  return `${status}`;
};

export const fetchCustomerDetailsURL = (page, search) => {
  return `/customer/all?page=${page}${search}`;
};

export const fetchCustomerFinanceDetailsURl = (page, search) => {
  return `/customer/details?page=${page}${search}`;
};

export const editVehicleDetailsURL = (id) => {
  return `/vehicle/edit/${id}`;
};

export const addVehicleURL = () => {
  return '/vehicle/add';
};

export const fetchVehicleDataURL = (id) => {
  return `/general/vehicle_data?vehicle_no=${id}`;
};

export const fetchVehicleDetailsURL = (id) => {
  return `/vehicle/edit/${id}`;
};

export const getVehicleDocumentUrl = () => {
  return `/vehicle/get-vehicle-document`;
};

export const fetchDriverDetailsURL = (id) => {
  if (id) {
    return `/vendor/driver?driver_id=${id}`;
  } else {
    return `/vendor/driver`;
  }
};

export const editDriverDetailsURL = (id) => {
  return `/vendor/driver?driver_id=${id}`;
};

export const addDriverURL = () => {
  return `/vendor/driver`;
};

export const addRouteTatURL = () => {
  return `/booking/route-tat`;
};

export const fetchDriverLicenseURL = () => {
  return `/vendor/fetch-driver-details`;
};

export const editDriverPhoneURL = () => {
  return `/vendor/edit-driver-phone`;
};

export const verifyCustomerURL = () => {
  return '/customer/verify-otp';
};

export const sendOTPURL = () => {
  return '/customer/generate-otp';
};

export const addCustomerDetailsURL = (customerId) => {
  return `/customer/add-details/${customerId}`;
};

export const addCustomerURL = () => {
  return '/customer/add';
};

export const getCustomerDetailsURL = (customerId) => {
  return `/customer/get_customer_detail/${customerId}`;
};

export const editCustomerURL = (customerId) => {
  return `/customer/edit/${customerId}`;
};

export const fetchCustomerInitialDataURL = () => {
  return `/customer/customer-initial-data`;
};
export const fetchCustomerInitialDataCSCCountryURL = (filter) => {
  const {country} = filter;
  return `/general/state-city?country=${country}`;
};
export const fetchCustomerInitialDataCSCStateURL = (filter) => {
  const {state} = filter;
  return `/general/state-city?state=${state}`;
};

export const editCustomerDetailsURL = (customerId) => {
  return `/customer/edit-details/${customerId}`;
};

export const checkAvailabilityURL = () => {
  return '/customer/check-availability';
};

export const addCityRecordURL = () => {
  return `/booking/all-cities`;
};

export const addConsignorURL = () => {
  return `/booking/add-edit-consignor`;
};
export const fetchConsignorRecordsURL = (page, search) => {
  return `/booking/add-edit-consignor?page=${page}${search}`;
};

export const fetchDistanceMapURL = (page, search) => {
  return `/booking/distance-map?page=${page}${search}`;
};

export const addUpdateDistanceMapURL = () => {
  return `/booking/distance-map`;
};

export const fetchUserDetailsURL = (page, search) => {
  return `/internal/user/all?page=${page}${search}`;
};

export const addUserDetailsURL = () => {
  return '/internal/user/add';
};

export const updateUserDetailsURL = (userId) => {
  return `/internal/user/edit/${userId}`;
};

export const sendRecordDocumentsURL = (url) => {
  return `/${url}`;
};

export const fetchChangePasswordURL = () => {
  return `/internal/user/change-password`;
};

export const approveCustomerURL = () => {
  return '/customer/approve-details';
};

export const generateNewOtpURL = () => {
  return `/customer/generate-otp`;
};

export const updateVehicleAvailabilityURL = (vehicleId) => {
  return `/vehicle/unavailable/${vehicleId}`;
};
export const fetchVehicleDocumentHistoryURL = (vehicleId) => {
  return `/vehicle/document-history/${vehicleId}`;
};

export const fetchGeneralVehicleDataURL = (id) => {
  return `/general/vehicle_details?vehicle_no=${id}`;
};

export const fetchBillingEntityURL = (page, search) => {
  return `/vendor/billing-entity?page=${page}${search}`;
};

export const fetchApproveOrRejectURL = () => {
  return `vendor/billing-entity`;
};

export const savePaymentDataUrl = () => {
  return `/finance/edit-beneficiary`;
};

export const editBillingEntityUrl = () => {
  return `/vendor/edit-billing-entity`;
};

export const downloadBillingEntityRecordURL = () => {
  return `vendor/billing-entity`;
};

export const downloadPaymentEntityRecordURL = () => {
  return `finance/payment-entity`;
};

export const extractDocDataURL = () => {
  return `/general/extract-docs-data`;
};

//Product
export const fetchProductsDataURL = (page, search) => {
  return `/customer/customer-product/?page=${page}${search}`;
};

export const addProductDataURL = () => {
  return `customer/customer-product/`;
};

export const editProductDataURL = (productId) => {
  return `customer/customer-product/${productId}/`;
};

//Pricing
export const fetchPricingDataURL = (customerId, category, page, search) => {
  return `/customer/customer-pricing/?customer_id=${customerId}&category=${category}&page=${page}${search}`;
};

export const fetchTonnageTypesURL = () => {
  return `customer/customer-pricing/tonnage_types/`;
};

export const fetchPricingLatestUploadsURL = (customerId) => {
  return `/customer/customer-pricing/latest_upload/?customer_id=${customerId}`;
};

export const fetchUploadCategoriesURL = (customerId) => {
  return `/customer/customer-pricing/uploader_data/?customer_id=${customerId}`;
};

export const downloadPricingReportURL = () => {
  return `/customer/customer-pricing/download/`;
};

//PO Mapping
export const fetchPrODataURL = (page, search) => {
  return `/internal/po-hierarchy?page=${page}${search}`;
};

export const addPODataURL = () => {
  return `/internal/po-hierarchy`;
};

export const editPODataURL = (poID) => {
  return `/internal/po-hierarchy/${poID}`;
};

//Warehouse
export const fetchWareHouseData = (page = 1, customer_id, extraparams) => {
  if (extraparams?.length > 0) {
    return `/customer/customer-warehouse/?page=${page}${extraparams}&customer_id=${customer_id}`;
  } else {
    return `/customer/customer-warehouse/?page=${page}&customer_id=${customer_id}`;
  }
};

export const saveWareHouseData = (wareHouseId) => {
  return `/customer/customer-warehouse/${wareHouseId ? `${wareHouseId}/` : ''}`;
};

export const downloadWareHouseReport = () => {
  return `/customer/customer-warehouse/download/`;
};

//Fulfilmemt
export const fetchFullFilmentData = (page = 1, customer_id, extraparams) => {
  if (extraparams?.length > 0) {
    return `/customer/customer-configuration/?page=${page}${extraparams}&customer_id=${customer_id}`;
  } else {
    return `/customer/customer-configuration/?page=${page}&customer_id=${customer_id}`;
  }
};

export const saveFullFillmentData = (id) => {
  let ID = id ? `${id}/` : '';
  return `customer/customer-configuration/${ID}`;
};

export const deleteConfigurations = (id) => {
  return `customer/customer-configuration/${id}/`;
};
